
import { defineComponent, nextTick, reactive, ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
// import sign from '@/components/sign.vue'
// import HeaderTit from '@/components/HeaderTit.vue'
import { getStorage } from '@/commons/SessionStorageUtils'
import AxiosAdapter from '@/request/AxiosAdapter'
import { ElMessage } from 'element-plus'
import LinkAge from '@/components/Selection.vue'
import { StringObjectType } from '@/commons/Types'
import { getRefFunc, requestFunc } from '@/service/Linkage'
import { emailReg, IDReg, mobileReg } from '@/commons/unit'
import { throwCustomFunction } from '@/commons/ErrorHandle'
import PreView from '@/components/preview/PreView'
import signature from './signature.vue'
// import { nextTick } from '../../../preservation-mpvue/static/vant/common/utils'

export default defineComponent({
  name: 'ConfirmAddressNormal',
  components: { LinkAge, signature },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const signature = ref(null)
    const agentSignature = ref(null)
    const areaName = ref('')
    const isHavingImg = ref(false)
    const confirm = ref(true)
    const signSrc = ref('')
    const agentSignSrc = ref('')
    const disabled = ref(false)
    const sign = ref(null)
    const signShow = ref(false)
    const receiverName = ref()
    const signImgStr = ref()
    const agentSignShow = ref(false)
    const agentSignImgStr = ref()
    const newData =
      new Date().getFullYear() +
      '年' +
      (new Date().getMonth() + 1) +
      '月' +
      new Date().getDate() +
      '日'
    let courtName = ''
    const getUrl = process.env.VUE_APP_BASE_API
    const verifyCode = ref('')
    const dialogVisible = ref(false)
    const urls = reactive({
      frontUrl: '',
      reverseUrl: ''
    })
    const areaOptions: StringObjectType<any> = reactive({
      shengList: [],
      shiList: [],
      quList: []
    })

    const signs = reactive([
      {
        text: '当事人(签章):',
        bindField: signature,
        title: 'signature',
        isHavingImg: false,
        img: ''
      }
    ])
    const agentSigns = reactive([
      {
        text: '委托代理人(签章):',
        bindField: agentSignature,
        title: 'agentSignature',
        isHavingImg: false,
        img: ''
      }
    ])
    const isElectronicList = ref([
      {
        code: true,
        name: '是'
      },
      {
        code: false,
        name: '否'
      }
    ])
    const identityTypeList = ref([
      {
        code: 1,
        name: '居民身份证'
      },
      {
        code: 2,
        name: '中国公民护照'
      },
      {
        code: 7,
        name: '统一社会信用代码'
      },
      {
        code: 9,
        name: '律师证'
      }
    ])
    const armyPropertiesList = ref([
      {
        code: 1,
        name: '军队单位'
      },
      {
        code: 2,
        name: '军人'
      },
      {
        code: 3,
        name: '军属'
      },
      {
        code: '',
        name: '无'
      }
    ])
    const province = ref('')
    // 初始化省
    requestFunc().then((value) => (areaOptions.shengList = value))
    let cityResolve
    let districtResolve
    const cityPromise = new Promise<void>((resolve) => (cityResolve = resolve))
    const districtPromise = new Promise<void>(
      (resolve) => (districtResolve = resolve)
    )

    const city = getRefFunc(areaOptions, 'shiList', province, true, cityResolve)

    const district = getRefFunc(
      areaOptions,
      'quList',
      city,
      false,
      districtResolve
    )
    const ruleForm: any = reactive({
      id: '',
      name: '',
      identityType: '' || null,
      identityNumber: '',
      mobilephone: '',
      mobilephone2: '',
      isElectronic: false,
      detailAddress: '',
      email: '',
      confirm: true,
      institutionId: '',
      caseCause: '',
      signature: '',
      agentSignature: '',
      province,
      city,
      district,
      provinceCode: '',
      cityCode: '',
      districtCode: '',
      caseNumberWord: '',
      postalCode: '',
      armyProperties: ''
    })
    if (route.query && typeof route.query.courtName === 'string') {
      courtName = route.query.courtName
    }
    if (
      route.query.institutionId == '450205' ||
      route.query.institutionId == '450103' ||
      route.query.institutionId == '450127' ||
      route.query.institutionId == '450221'
    ) {
      disabled.value = true
    } else {
      disabled.value = false
    }
    const submit = async () => {
      if (!ruleForm.name) {
        throwCustomFunction('请输入姓名')
      }
      if (ruleForm.identityType == 1) {
        if (!IDReg.test(ruleForm.identityNumber)) {
          throwCustomFunction('请输入正确的身份证号')
        }
      }
      if (ruleForm.identityType != 1) {
        if (!ruleForm.identityNumber) {
          throwCustomFunction('请输入身份证号')
        }
      }
      if (!mobileReg.test(ruleForm.mobilephone)) {
        throwCustomFunction('请输入正确的手机号')
      }

      if (ruleForm.mobilephone2) {
        if (!mobileReg.test(ruleForm.mobilephone2)) {
          throwCustomFunction('请输入正确的其他联系方式')
        }
      }

      if (!ruleForm.city) {
        throwCustomFunction('请选择所在地区')
      }
      if (!ruleForm.detailAddress) {
        throwCustomFunction('请输入详细地址')
      }

      if (ruleForm.email) {
        if (!emailReg.test(ruleForm.email)) {
          throwCustomFunction('请输入正确电子邮件地址')
        }
      }

      if (!ruleForm.confirm) {
        throwCustomFunction('请确认送达内容')
      }
      if (!route.query.institutionId) {
        throwCustomFunction('无法获取当前法院')
      }

      ruleForm.institutionId = route.query.institutionId
      ruleForm.provinceCode = ruleForm.province
      ruleForm.cityCode = ruleForm.city
      ruleForm.districtCode = ruleForm.district
      let canSubmit = false
      signs.forEach((current) => {
        if (current.isHavingImg) {
          canSubmit = true
          return
        }
        // const val: any = current.bindField
        // if (val.linen.length > 60) {
        //   canSubmit = true
        if (signImgStr.value) {
          canSubmit = true
          ruleForm[current.title] = signImgStr.value
        }
        // ruleForm[current.title] = signImgStr.value
        // }
      })
      agentSigns.forEach((current) => {
        if (current.isHavingImg) {
          canSubmit = true
          return
        }
        // const val: any = current.bindField
        // if (val.linen.length > 60) {
        //   canSubmit = true
        if (agentSignImgStr.value) {
          canSubmit = true
          ruleForm[current.title] = agentSignImgStr.value
        }
        // ruleForm[current.title] = agentSignImgStr.value
        // }
      })
      if (!canSubmit) {
        throwCustomFunction('请手写签名')
      }
      if (!ruleForm.confirm) {
        throwCustomFunction('请确认送达内容')
      }

      const data: any = await AxiosAdapter.post({
        url: '/confirmAddress/addAddress',
        data: ruleForm
      })
      ElMessage.success({
        type: 'success',
        message: '操作成功',
        duration: 2000
      })

      // return
      PreView(data, router)
      // return
      // router.push({
      //   path: '/confirm-address-detail',
      //   query: {
      //     url: data
      //     // url: 'https://www.i12368.com/webNew/files/2020/340123/2020111823300000007/2020111823300000011/2020121523300000007/delivery/signPdf/2020111823300000007.pdf'
      //     // url: 'https://www.i12368.com/webNew/files/2020/electronic/2020111823300000007/delivery/32388e69bc624fbda32a050ecd64f877.jpeg'
      //   }
      // })
    }

    const gotoPage = () => {
      router.back()
    }
    const modifyMessage = () => {
      dialogVisible.value = true
    }
    const cancelGetCode = () => {
      dialogVisible.value = false
      verifyCode.value = ''
    }
    const verifyCodeFun = async (verifyCode?: string) => {
      // const params = {
      //   code: verifyCode,
      //   institutionId: route.query.institutionId
      // }
      const data: any = await AxiosAdapter.post({
        url:
          '/confirmAddress/checkRandomCode?institutionId=' +
          route.query.institutionId +
          '&code=' +
          verifyCode
      })
      ruleForm.id = data.id
      ruleForm.provinceCode = data.provinceCode
      ruleForm.cityCode = data.cityCode
      ruleForm.districtCode = data.districtCode
      ruleForm.institutionId = data.institutionId
      ruleForm.caseCause = data.caseCause
      ruleForm.caseNumberWord = data.caseNumberWord
      ruleForm.name = data.name
      ruleForm.identityType = data.identityType
      ruleForm.identityNumber = data.identityNumber
      ruleForm.mobilephone = data.mobilephone
      ruleForm.mobilephone2 = data.mobilephone2
      ruleForm.email = data.email
      ruleForm.postalCode = data.postalCode
      ruleForm.detailAddress = data.detailAddress
      ruleForm.isElectronic = false
      ruleForm.signature = data.signature
      ruleForm.agentSignature = data.agentSignature
      province.value = data.provinceCode
      city.value = data.cityCode
      district.value = data.districtCode
      cityPromise.then(() => (city.value = data.cityCode))
      districtPromise.then(() => (district.value = data.districtCode))
      signs.forEach((item) => {
        const img = data[item.title]
        if (img) {
          item.isHavingImg = true
          item.img = getUrl + img
          signSrc.value = process.env.VUE_APP_BASE_API + data[item.title]
        }
      })
      agentSigns.forEach((item) => {
        const img = data[item.title]
        if (img) {
          item.isHavingImg = true
          item.img = getUrl + img
          agentSignSrc.value = process.env.VUE_APP_BASE_API + data[item.title]
        }
      })
      if (data.id) {
        ruleForm.signature = ''
        ruleForm.agentSignature = ''
      }
    }
    const confirmGetCode = async () => {
      if (verifyCode.value) {
        await verifyCodeFun(verifyCode.value)
        cancelGetCode()
      }
    }

    const clearSignature = async (current: any) => {
      // const fieldItem = current.bindField
      const val: any = current.bindField
      val.clear()
    }

    const reset = async (current: any) => {
      current.isHavingImg = false
      const val: any = current.bindField
      nextTick(() => {
        val.clear()
      })
    }

    const signreturnEvent = () => {
      signShow.value = false
    }

    const signEvent = (e: any) => {
      const val: any = signs[0]
      val.isHavingImg = false
      signShow.value = false
      signImgStr.value = e
    }
    const agentSignreturnEvent = () => {
      agentSignShow.value = false
    }

    const agentSignEvent = (e: any) => {
      const val: any = agentSigns[0]
      val.isHavingImg = false
      agentSignShow.value = false
      agentSignImgStr.value = e
    }

    const signShowFun = () => {
      signShow.value = true
    }
    const agentSignShowFun = () => {
      agentSignShow.value = true
    }
    onMounted(() => {
      ruleForm.institutionId = route.query.institutionId
    })

    return {
      submit,
      agentSigns,
      gotoPage,
      modifyMessage,
      getStorage,
      getUrl,
      urls,
      agentSignature,
      signature,
      signSrc,
      signShow,
      agentSignImgStr,
      courtName,
      signs,
      ruleForm,
      clearSignature,
      reset,
      dialogVisible,
      areaName,
      newData,
      identityTypeList,
      armyPropertiesList,
      isElectronicList,
      verifyCodeFun,
      agentSignShow,
      confirmGetCode,
      verifyCode,
      signShowFun,
      cancelGetCode,
      areaOptions,
      signreturnEvent,
      signEvent,
      isHavingImg,
      receiverName,
      signImgStr,
      agentSignEvent,
      agentSignShowFun,
      agentSignreturnEvent,
      agentSignSrc,
      disabled
    }
  }
})
