
import { defineComponent, PropType, computed } from 'vue'

interface DisplayVo {
  label: string
  value: string
}

export default defineComponent({
  name: 'Selection.vue',
  props: {
    list: {
      type: Array as PropType<any[]>,
      default: []
    },
    labelAndValue: {
      type: Object as PropType<DisplayVo>,
      default: {
        label: 'name',
        value: 'code'
      }
    },
    modelValue: { type: [Number, String], default: '' }
  },
  setup(props, { emit }) {
    const val = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })
    return {
      val
    }
  }
})
