import {Router} from "vue-router"

export function isImg(url: string): boolean {
    return /\.(png|jpe?g|gif|svg)(\?.*)?$/.test(url)
}

export function isPdf(url: string): boolean {
    return /\.(pdf|PDF)(\?.*)?$/.test(url)
}

export function isDocx(url: string): boolean {
    return /\.(docx|dotx|doc|dot|docm|dotm)(\?.*)?$/.test(url)
}

/**
 * 单个文件浏览
 */
export default function PreView(url: string, router?: Router): void {
    if (isImg(url)) {
        router && router.push({
            path: '/img-pre-view',
            query: {
                url
            }
        })
    } else if (isPdf(url)) {
        //  window.open('http://192.168.1.100/pdf/?pdfUrl=')
        // const filePath: any = process.env.VUE_APP_PDF_VIEW_URL
        // window.open(filePath + encodeURI(url))
        // window.open(url)
        location.replace(url)
        // filePath += '/' + encodeURI(url)

        // 加密
        // filePath = encode(filePath)
        // if (filePath) {
        //     const viewUrl: string = process.env.VUE_APP_PDF_VIEW_URL + filePath
        //     location.href = viewUrl
        //     if (viewUrl.length >= 255) {
        //         // 因文件名过长，请您下载文件
        //         throwCustomFunction('因文件名过长，请您下载文件')
        //     } else {
        //         location.href = viewUrl
        //     }
        // }
    } else {
        location.href = process.env.VUE_APP_PROXY_TARGET + url
    }
}
