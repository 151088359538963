import { Area } from "@/commons/Types"
import AxiosAdapter from "@/request/AxiosAdapter"
import { Ref, ref, watchEffect } from "vue"

export const requestFunc = async (code?: number, flag?: any) => {
  if (code) {
    if (flag) return await AxiosAdapter.get<any, Area[]>({url: '/city/list', data: { parentCode: code }})
    else return await AxiosAdapter.get<any, Area[]>({url: '/city/list', data: { parentCode: code }})
  } else {
    return await AxiosAdapter.get<any, Area[]>({url: '/city/list'})
  }
}
  
export const getRefFunc = (target: any, key: string, refs: Ref, flag?: any, resolve?: (value: void | PromiseLike<void>) => void) => {
  const val:any = ref('')
  let init = true
  watchEffect(async () => {
    val.value = ''
    if (!refs.value) {
      target[key] = []
      return
    }
    target[key] = await requestFunc(refs.value, flag)
    if (resolve && init) {
      resolve()
    }
    init = false
  })
  return val
}